import React, { FC } from 'react';
import Modal from '@common/modal';
import { useToast } from '@common/toast';
import { useTermsCheckNew, useTermsOne, useTermsAgreeForSn } from '@components/acca/test/query/useTerms';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { TERMS } from '@domain/constant/v1/termsType';
import useDebounce from '@utils/hooks/useDebounce';
import classnames from 'classnames/bind';
import styles from './terms.module.scss';

const cx = classnames.bind(styles);

interface ITermsModalProps {
  termsType: TERMS.ACC_PRIVACY_POLICY | TERMS.ACC_TRIAL_PRIVACY_POLICY;
  visible: boolean;
  onConfirm: () => void;
  onClose: () => void;
  dimmed?: boolean;
}

const TermsModal: FC<ITermsModalProps> = ({ termsType, visible, onConfirm, onClose, dimmed = true }) => {
  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const Toast = useToast();

  const { data, isFetched } = useTermsCheckNew(termsType, isLogin && visible);
  const { data: tutorialTermsData } = useTermsOne(data?.termsSn, isFetched);

  const { mutate: termsAgreeMutate } = useTermsAgreeForSn(
    (rs) => {
      if (rs) {
        onConfirm();
      }
    },
    (err) => {
      switch (err?.response?.data?.errorCode) {
        case 'B901':
          onConfirm();
          return;
        default:
          Toast({ type: 'error', iconType: 'info', content: err?.response?.data?.message });
          return;
      }
    },
  );

  const onSubmit = useDebounce(() => {
    if (!tutorialTermsData) return;
    termsAgreeMutate(tutorialTermsData.sn);
  }, 500);

  return (
    <Modal visible={visible} dimmed={dimmed}>
      <div className={cx('layerTerms')}>
        <strong className={cx('title')}>개인정보 수집 및 이용 동의</strong>

        <div className={cx('termsArea')}>
          <div className={cx('termsWrap')}>
            <div
              className={cx('termsText')}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: tutorialTermsData?.contents ?? '',
              }}
            />
          </div>
        </div>

        <a className={cx('btnAgreement')} role="button" onClick={onSubmit}>
          동의하기
        </a>

        <a className={cx('btnClose')} role="button" onClick={onClose}>
          <span className={cx('hidden')}>close</span>
        </a>
      </div>
    </Modal>
  );
};

export default TermsModal;
