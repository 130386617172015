import { FC, useEffect, useState } from 'react';
import Text from '@components/common/text';
import Button from '@components/common/button';
import Checkbox from '@components/common/checkbox';
import Icon from '@components/common/assets';
import ResponsiveModal from '@components/_atoms/ResponsiveModal';
import { useAccLightTasks } from '@repository/accLight/useAccLight';
import { AccLightApplyTaskRq } from '@domain/rq/accLight/AccLightApplyTaskRq';
import { ApplyRequiredTaskVO } from '@domain/vo/accLight/AccLightApplyTaskListVO';
import { AccLightApplyHistoryItemDto } from '@domain/rs/accLight/AccLightApplyHistoryRs';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface ItemProps {
  data: ApplyRequiredTaskVO;
  onClick: (sn?: number) => void;
  selected: (sn?: number) => boolean;
}

const Item: FC<ItemProps> = ({ data, onClick, selected }) => {
  const handleOnClick = (e: any) => {
    e.preventDefault();
    onClick(data?.taskSn);
  };

  return (
    <button
      className={cx('problem', { selected: selected(data?.taskSn) })}
      onClick={handleOnClick}
      disabled={!data.selectAvailable}
    >
      <Checkbox
        className={cx('checkbox')}
        width={24}
        height={24}
        onClick={handleOnClick}
        checked={selected(data.taskSn)}
        disabled={!data.selectAvailable}
      />
      <div className={cx('info')}>
        <Icon name={data.iconName as any} width={40} height={40} />
        <span
          className={cx('name', { disabled: !data.selectAvailable })}
          dangerouslySetInnerHTML={{ __html: data.taskName }}
        />
      </div>
    </button>
  );
};

interface SelectItemProps {
  selectedResult: AccLightApplyHistoryItemDto;
  isJD?: boolean;
  onPrev: () => void;
  positionSn: number;
  onSubmit: (rq: AccLightApplyTaskRq) => void;
  onApply: (rq: AccLightApplyTaskRq) => void;
}

const SelectItem: FC<SelectItemProps> = ({ isJD, onPrev, positionSn, selectedResult, onSubmit, onApply }) => {
  const [selectedTaskSns, setSelectedTaskSns] = useState<number[]>([]);
  const selected = (index?: number) => {
    if (!index) return false;

    return selectedTaskSns.includes(index);
  };

  const { data } = useAccLightTasks({
    positionSn,
    code: selectedResult.code,
    messageRequestYn: !isJD,
  });

  const onClick = (task: ApplyRequiredTaskVO) => {
    if (!task.selectAvailable || !task.taskSn) return;
    if (selectedTaskSns.includes(task.taskSn)) {
      setSelectedTaskSns(selectedTaskSns.filter((e) => e !== task.taskSn));
    } else {
      setSelectedTaskSns([...selectedTaskSns, task.taskSn]);
    }
  };

  const tendencyEls = data?.requiredTasks
    .filter((task) => task.itemType === 'tendency')
    .map((task) => <Item key={task.taskSn} data={task} selected={selected} onClick={() => onClick(task)} />);
  const gameEls = data?.requiredTasks
    .filter((task) => task.itemType === 'game')
    .map((task) => <Item key={task.taskSn} data={task} selected={selected} onClick={() => onClick(task)} />);
  const interviewEls = data?.requiredTasks
    .filter((task) => task.itemType === 'interview')
    .map((task) => <Item key={task.taskSn} data={task} selected={selected} onClick={() => onClick(task)} />);

  const handleConfirm = async () => {
    if (!data) return;

    const rq = {
      positionSn,
      selectedTaskSns,
      code: selectedResult.code,
      requiredTasks: data.requiredTasks,
      messageRequestYn: !isJD,
    };

    if (selectedTaskSns.length === data?.requiredTasks.length) {
      onSubmit(rq);
    } else {
      onApply(rq);
    }
  };

  useEffect(() => {
    if (!data) return;
    const isAvailableSns = data.requiredTasks
      .filter((task) => task.selectAvailable && !!task.taskSn)
      .map((task) => task.taskSn);
    setSelectedTaskSns(isAvailableSns as number[]);
  }, [data]);

  return (
    <div className={cx('wrap')}>
      <div className={cx('titleArea')}>
        <Text fontStyle={FontStyle.H4_B}>
          응시할 과제를 확인하고
          <br />
          결과표에서 불러올 과제를 선택하세요.
        </Text>
        <Text fontStyle={FontStyle.B2_M} color={Colors.C_COOL_GRAY_70}>
          불러오지 않은 과제는 응시가 필요해요.
        </Text>
      </div>
      <ResponsiveModal.ScrollArea maxHeight={660}>
        <div className={cx('itemList')}>
          {!!tendencyEls?.length && (
            <div>
              <Text fontStyle={FontStyle.B2_B}>성향파악</Text>
              <div className={cx('problemList')}>{tendencyEls}</div>
            </div>
          )}
          {!!gameEls?.length && (
            <div>
              <Text fontStyle={FontStyle.B2_B}>전략 게임</Text>
              <div className={cx('problemList')}>{gameEls}</div>
            </div>
          )}
          {!!interviewEls?.length && (
            <div>
              <Text fontStyle={FontStyle.B2_B}>영상면접</Text>
              <div className={cx('problemList')}>{interviewEls}</div>
            </div>
          )}
        </div>
      </ResponsiveModal.ScrollArea>
      <div className={cx('btnArea')}>
        <Button theme="secondary" className={cx('btn')} onClick={onPrev}>
          이전
        </Button>
        <Button className={cx('btn')} onClick={handleConfirm}>
          확인
        </Button>
      </div>
    </div>
  );
};

export default SelectItem;
