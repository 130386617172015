import { FC, useState } from 'react';
import Text from '@components/common/text';
import Button from '@components/common/button';
import Icon from '@components/common/assets';
import { useToast } from '@components/common/toast';
import { useAccLightNewApply, useAccLightPostTasks } from '@repository/accLight/useAccLight';
import { useApplicationPositionAccStatus } from '@repository/match';
import { useMessageActions } from '@stores/MessageStore';
import { AccLightApplyTaskRq } from '@domain/rq/accLight/AccLightApplyTaskRq';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import UrlUtil from '@utils/urlUtil';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);
interface AccLightConfirmModalProps {
  isNewApply?: boolean;
  isJD: boolean;
  positionSn: number;
  taskRequest: AccLightApplyTaskRq | null;
  onClose: () => void;
  onPrev: () => void;
}

export const AccLightApplyModal: FC<AccLightConfirmModalProps> = ({
  isNewApply,
  isJD = true,
  positionSn,
  taskRequest,
  onPrev,
  onClose,
}) => {
  const Toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { fetchMessagesList } = useMessageActions();
  const { refetch } = useApplicationPositionAccStatus(positionSn, { enabled: false });
  const { mutateAsync: fetchAccUrl } = useAccLightPostTasks();

  const submitError = (e: any) => {
    switch (e?.response?.data?.errorCode) {
      default:
        setIsLoading(false);
        Toast({ type: 'error', iconType: 'info', content: e?.response?.data?.message });
        onClose();
    }
  };

  // APPLY MODAL
  const handleApply = async () => {
    if (!taskRequest || isLoading) return;
    setIsLoading(true);
    const { applyUrl, code } = await fetchAccUrl(taskRequest, { onError: submitError });

    UrlUtil.openWindowWithPost(applyUrl, {
      authenticationCode: code,
    });
    setIsLoading(false);
    await refetch();
    if (!isJD) await fetchMessagesList(positionSn);
    onClose();
  };

  return (
    <div className={cx('wrap')}>
      <div className={cx('content')}>
        <Icon name="errorYellowFill" width={64} height={64} />
        <Text fontStyle={FontStyle.H4_B}>응시 시작 전에 확인해 주세요.</Text>
      </div>

      <div className={cx('noticeArea')}>
        <div className={cx('notice')}>
          <Icon name="checkLight" width={24} height={24} fill={Colors.C_COOL_GRAY_60} />
          <Text fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_70}>
            한 번 응시를 시작하면 변경이 불가능합니다.
          </Text>
        </div>
        <div className={cx('notice')}>
          <Icon name="checkLight" width={24} height={24} fill={Colors.C_COOL_GRAY_60} />
          <Text fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_70}>
            반드시 제출 마감 전까지 응시를 완료해 주세요.
          </Text>
        </div>
      </div>

      <div className={cx('btnArea')}>
        <Button theme="secondary" className={cx('btn')} onClick={onPrev}>
          {isJD ? '이전' : isNewApply ? '닫기' : '이전'}
        </Button>
        <Button className={cx('btn')} onClick={handleApply} isLoading={isLoading}>
          응시하기
        </Button>
      </div>
    </div>
  );
};

interface AccLightNewApplyModalProps {
  isNewApply?: boolean;
  isJD: boolean;
  positionSn: number;
  onClose: () => void;
  onPrev: () => void;
}

export const AccLightNewApplyModal: FC<AccLightNewApplyModalProps> = ({
  isNewApply,
  isJD = true,
  onPrev,
  positionSn,

  onClose,
}) => {
  const Toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: fetchAccNewApply } = useAccLightNewApply();
  const { fetchMessagesList } = useMessageActions();
  const { refetch } = useApplicationPositionAccStatus(positionSn, { enabled: false });

  const submitError = (e: any) => {
    switch (e?.response?.data?.errorCode) {
      default:
        setIsLoading(false);
        Toast({ type: 'error', iconType: 'info', content: e?.response?.data?.message });
        onClose();
    }
  };

  //응시하기
  const handleNewApply = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const { url, code } = await fetchAccNewApply({ positionSn, messageRequestYn: !isJD }, { onError: submitError });

    UrlUtil.openWindowWithPost(url, {
      authenticationCode: code,
    });

    setIsLoading(false);
    await refetch();
    if (!isJD) await fetchMessagesList(positionSn);
    onClose();
  };

  return (
    <div className={cx('wrap')}>
      <div className={cx('content')}>
        <Icon name="errorYellowFill" width={64} height={64} />
        <Text fontStyle={FontStyle.H4_B}>응시 시작 전에 확인해 주세요.</Text>
      </div>

      <div className={cx('noticeArea')}>
        <div className={cx('notice')}>
          <Icon name="checkLight" width={24} height={24} fill={Colors.C_COOL_GRAY_60} />
          <Text fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_70}>
            한 번 응시를 시작하면 변경이 불가능합니다.
          </Text>
        </div>
        <div className={cx('notice')}>
          <Icon name="checkLight" width={24} height={24} fill={Colors.C_COOL_GRAY_60} />
          <Text fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_70}>
            반드시 제출 마감 전까지 응시를 완료해 주세요.
          </Text>
        </div>
      </div>

      <div className={cx('btnArea')}>
        <Button theme="secondary" className={cx('btn')} onClick={onPrev}>
          {isJD ? '이전' : isNewApply ? '닫기' : '이전'}
        </Button>
        <Button className={cx('btn')} onClick={handleNewApply} isLoading={isLoading}>
          응시하기
        </Button>
      </div>
    </div>
  );
};
