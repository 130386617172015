export enum AccLightTaskTypeEnum {
  LIKERT = 'LIKERT', //나 알아보기
  META_LIKERT = 'META_LIKERT', //타인 관점에서 나 알아보기
  BIPOLAR = 'BIPOLAR', //하나만 선택하기
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE', //여러개 선택하기

  MRT = 'MRT', //도형 회전하기
  RPS = 'RPS', //가위바위보
  PM = 'PM', //약속 정하기
  RMT = 'RMT', //길 만들기
  PCT2 = 'PCT2', //마법약 만들기
  FNB = 'FNB', //도형 순서 기억하기
  OTN = 'OTN', //숫자 누르기
  WNC = 'WNC', //개수 비교하기
  HAS = 'HAS', //고양이 술래잡기

  VIDEO_BASIC_EXPERIENCE = 'VIDEO_BASIC_EXPERIENCE', //경험 면접
  VIDEO_BASIC_VALUES = 'VIDEO_BASIC_VALUES', //가치관 면접
  VIDEO_BASIC_FREE_CHOICE = 'VIDEO_BASIC_FREE_CHOICE', //질문 선택 면접
  VIDEO_BASIC_SITUATION = 'VIDEO_BASIC_SITUATION', //상황 면접
  VIDEO_BASIC_STRENGTH_WEAKNESS = 'VIDEO_BASIC_STRENGTH_WEAKNESS', //강약점 면접
  CUSTOM_INTERVIEW = 'CUSTOM_INTERVIEW', //커스텀 면접
}

export type AccLightTaskType = keyof typeof AccLightTaskTypeEnum;
