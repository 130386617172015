import { FC } from 'react';
import Text from '@components/common/text';
import Button from '@components/common/button';
import Icon from '@components/common/assets';
import { useToast } from '@components/common/toast';
import { useMessageActions } from '@stores/MessageStore';
import { useApplicationPositionAccStatus } from '@repository/match';
import { useAccLightPostTasks } from '@repository/accLight/useAccLight';
import { AccLightApplyTaskRq } from '@domain/rq/accLight/AccLightApplyTaskRq';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import SessionStorageUtil from '@utils/storage/sessionStorage';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface SubmitProps {
  isJD: boolean;
  messageSn?: number;
  positionSn: number;
  taskRequest: AccLightApplyTaskRq | null;
  onPrev: () => void;
  onClose: () => void;
}

const Submit: FC<SubmitProps> = ({ isJD, messageSn, onPrev, taskRequest, positionSn, onClose }) => {
  const Toast = useToast();
  const { fetchMessagesList } = useMessageActions();
  const { refetch } = useApplicationPositionAccStatus(positionSn, { enabled: false });
  const { mutateAsync: fetchAccUrl, isLoading } = useAccLightPostTasks();

  const submitSuccess = async () => {
    if (isJD) {
      await refetch();
    } else {
      SessionStorageUtil.setItem('accLightStatus', messageSn);
      await fetchMessagesList(positionSn);
    }

    Toast({
      type: 'result',
      iconType: 'info',
      content: '역량검사 결과를 성공적으로 제출하였습니다.',
    });

    onClose();
  };

  const submitError = (e: any) => {
    switch (e?.response?.data?.errorCode) {
      default:
        Toast({ type: 'error', iconType: 'info', content: e?.response?.data?.message });
        onClose();
    }
  };

  const handleSubmit = async () => {
    if (!taskRequest) return;

    fetchAccUrl(taskRequest, {
      onSuccess: submitSuccess,
      onError: submitError,
    });
  };

  return (
    <div className={cx('wrap')}>
      <div className={cx('content')}>
        <Text fontStyle={FontStyle.H4_B}>제출 전 꼭 확인하세요.</Text>
      </div>

      <div className={cx('noticeArea')}>
        <div className={cx('notice')}>
          <Icon name="checkLight" width={24} height={24} fill={Colors.C_COOL_GRAY_60} />
          <Text fontStyle={FontStyle.B3_M} color={Colors.C_COOL_GRAY_70}>
            응시 결과 제출 후에는 수정이 어려울 수 있어요.
          </Text>
        </div>
        <div className={cx('notice')}>
          <Icon name="checkLight" width={24} height={24} fill={Colors.C_COOL_GRAY_60} />
          <Text fontStyle={FontStyle.B3_M} color={Colors.C_COOL_GRAY_70}>
            제출한 결과는 지원현황에서 확인할 수 있어요.
          </Text>
        </div>
      </div>

      <div className={cx('btnArea')}>
        <Button theme="secondary" className={cx('btn')} onClick={onPrev}>
          이전
        </Button>
        <Button className={cx('btn')} onClick={handleSubmit} isLoading={isLoading}>
          제출하기
        </Button>
      </div>
    </div>
  );
};

export default Submit;
