import Modal from '@components/common/modal';
import CloseButton from '@components/common/closeButton';
import { useKeyDown } from '@utils/hooks/useKeyboard';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface ResponsiveModalProps {
  className?: string;
  visible: boolean;
  dimmed?: boolean;
  onClose?: () => void;
  onClickBg?: () => void;
  escape?: () => void;
  children: React.ReactNode;
}
const ResponsiveModal = ({
  className,
  visible,
  dimmed,
  onClose,
  onClickBg,
  escape,
  children,
}: ResponsiveModalProps) => {
  useKeyDown(() => {
    escape?.();
  });

  return (
    <Modal visible={visible} dimmed={dimmed} onClickBg={onClickBg}>
      <div className={cx('modalArea', className)}>
        {onClose && <CloseButton className={cx('btnClose')} onClick={onClose} />}
        {children}
      </div>
    </Modal>
  );
};

interface ScrollAreaProps {
  className?: string;
  maxHeight?: string | number;
  children: React.ReactNode;
}
const ScrollArea = ({ className, maxHeight, children }: ScrollAreaProps) => {
  return (
    <div className={cx('scrollArea', className)}>
      <div className={cx('scrollInner')} style={{ maxHeight }}>
        {children}
      </div>
    </div>
  );
};

ResponsiveModal.ScrollArea = ScrollArea;

export default ResponsiveModal;
