import {
  AccLightApplyTaskListRs,
  ApplyRequiredTaskDto,
  ApplyAvailableTaskDto,
} from '@domain/rs/accLight/AccLightApplyTaskListRs';
import { AccLightTaskTypeEnum } from '@domain/constant/accLight/taskType';

export class AccLightApplyTaskVO {
  requiredTasks: ApplyRequiredTaskVO[];
  availableTasks: ApplyAvailableTaskDto[];

  constructor(rs: AccLightApplyTaskListRs) {
    this.requiredTasks = rs.requiredTasks.map((task) => new ApplyRequiredTaskVO(task, rs.availableTasks));
    this.availableTasks = rs.availableTasks;
    // this.availableTasks = rs.availableTasks.map((task) => new ApplyAvailableTaskVO(task));
  }
}

export class ApplyRequiredTaskVO {
  taskSn?: number;
  taskType: AccLightTaskTypeEnum;
  taskName: string;
  iconName: string;
  selectAvailable: boolean;
  itemType: string | null;

  constructor(dto: ApplyRequiredTaskDto, dto2: ApplyAvailableTaskDto[]) {
    this.taskSn = dto.taskSn;
    this.taskType = dto.taskType;
    this.taskName = dto.taskName.replace(/\\n/g, '\n');
    this.iconName = this.getIcon(dto.taskType);
    this.selectAvailable = this.getSelectAvailable(dto2);
    this.itemType = this.getitemType(dto.taskType);
  }

  private getIcon = (type: string) => {
    switch (type) {
      case 'LIKERT':
        return 'accLikert';
      case 'META_LIKERT':
        return 'accMetaLikert';
      case 'BIPOLAR':
        return 'accBipolar';
      case 'MULTIPLE_CHOICE':
        return 'accMultipleChoiceText';

      case 'MRT':
        return 'accGame1';
      case 'RPS':
        return 'accGame2';
      case 'PM':
        return 'accGame3';
      case 'RMT':
        return 'accGame4';
      case 'PCT2':
        return 'accGame5';
      case 'FNB':
        return 'accGame6';
      case 'OTN':
        return 'accGame7';
      case 'WNC':
        return 'accGame8';
      case 'HAS':
        return 'accGame9';
      case 'VIDEO_BASIC_SITUATION':
        return 'accInterview1';
      case 'VIDEO_BASIC_EXPERIENCE':
        return 'accInterview2';
      case 'VIDEO_BASIC_VALUES':
        return 'accInterview3';
      case 'VIDEO_BASIC_FREE_CHOICE':
        return 'accInterview4';
      case 'VIDEO_BASIC_STRENGTH_WEAKNESS':
        return 'accInterview5';
      default:
        return 'accInterview6';
    }
  };

  private getSelectAvailable = (dto2: ApplyAvailableTaskDto[]) => {
    const availableTask = dto2.find((task) => task.taskType === this.taskType);
    return availableTask ? true : false;
  };

  private getitemType = (type: AccLightTaskTypeEnum) => {
    const tendency = [
      AccLightTaskTypeEnum.LIKERT,
      AccLightTaskTypeEnum.META_LIKERT,
      AccLightTaskTypeEnum.BIPOLAR,
      AccLightTaskTypeEnum.MULTIPLE_CHOICE,
    ];
    const game = [
      AccLightTaskTypeEnum.MRT,
      AccLightTaskTypeEnum.RPS,
      AccLightTaskTypeEnum.PM,
      AccLightTaskTypeEnum.RMT,
      AccLightTaskTypeEnum.PCT2,
      AccLightTaskTypeEnum.FNB,
      AccLightTaskTypeEnum.OTN,
      AccLightTaskTypeEnum.WNC,
      AccLightTaskTypeEnum.HAS,
    ];
    const interview = [
      AccLightTaskTypeEnum.VIDEO_BASIC_EXPERIENCE,
      AccLightTaskTypeEnum.VIDEO_BASIC_VALUES,
      AccLightTaskTypeEnum.VIDEO_BASIC_FREE_CHOICE,
      AccLightTaskTypeEnum.VIDEO_BASIC_SITUATION,
      AccLightTaskTypeEnum.VIDEO_BASIC_STRENGTH_WEAKNESS,
      AccLightTaskTypeEnum.CUSTOM_INTERVIEW,
    ];

    if (tendency.includes(type)) {
      return 'tendency';
    }
    if (game.includes(type)) {
      return 'game';
    }
    if (interview.includes(type)) {
      return 'interview';
    }
    return null;
  };
}
