import { FC, useEffect, useState } from 'react';
import Link from 'next/link';
import Text from '@components/common/text';
import Icon from '@components/common/assets';
import Button from '@components/common/button';
import ResponsiveModal from '@components/_atoms/ResponsiveModal';
import { useAccLightApplyHistory } from '@repository/accLight/useAccLight';
import { useTermsCheckNewMutation } from '@components/acca/test/query/useTerms';
import SelectItem from './selectItem';
import TermsModal from './terms';
import Submit from './submit';
import { AccLightNewApplyModal, AccLightApplyModal } from '../apply';
import { AccLightApplyTaskRq } from '@domain/rq/accLight/AccLightApplyTaskRq';
import { AccLightApplyHistoryItemDto } from '@domain/rs/accLight/AccLightApplyHistoryRs';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import { TERMS } from '@domain/constant/v1/termsType';
import useModals from '@utils/hooks/useModals';
import DateFormat from '@utils/date/format';
import useModal from '@utils/hooks/useModal';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface AccLightNewApplyProps {
  openModal: (type: string) => void;
}

const AccLightNewApply: FC<AccLightNewApplyProps> = ({ openModal }) => {
  const { mutateAsync: checkTermsMutateAsync } = useTermsCheckNewMutation();
  const { openModal: openTermsModal, closeModal, isModalOpen } = useModal();

  const handleContinueBtn = async () => {
    //약관 동의 여부 체크
    const { existsNewTermsAgree } = await checkTermsMutateAsync(TERMS.ACC_PRIVACY_POLICY);
    if (existsNewTermsAgree) {
      openTermsModal();
      return;
    }

    openModal('NEW_APPLY');
  };

  return (
    <>
      <button className={cx('item', 'new')} onClick={handleContinueBtn}>
        <div className={cx('column-left')}>
          <Text fontStyle={FontStyle.H5_B}>처음부터 응시하기</Text>
        </div>
        <div>
          <Icon name="largeArrowRightLight" width={32} height={32} />
        </div>
      </button>

      <TermsModal
        termsType={TERMS.ACC_PRIVACY_POLICY}
        visible={isModalOpen}
        onClose={closeModal}
        onConfirm={() => openModal('NEW_APPLY')}
        dimmed={true}
      />
    </>
  );
};

interface AccResultItemProps {
  data: AccLightApplyHistoryItemDto;
  onClick: () => void;
  isSelected: boolean;
}

const AccResultItem: FC<AccResultItemProps> = ({ isSelected, onClick, data }) => {
  return (
    <button className={cx('item', { isSelected })} onClick={onClick}>
      <div className={cx('column-left')}>
        <Text fontStyle={FontStyle.B1_B}>{data.title ?? '역량검사 결과표'}</Text>
        <div className={cx('info')}>
          <Text fontStyle={FontStyle.B3_M} color={Colors.C_COOL_GRAY_60}>
            {DateFormat.format(new Date(data.completedDate), 'yyyy.MM.dd HH:mm')} 완료
          </Text>
          {!!data.additionalNeededTaskCount ? (
            <Text className={cx('notice')} fontStyle={FontStyle.B3_M} color={Colors.C_COOL_GRAY_80}>
              {`추가 응시 ${data.additionalNeededTaskCount}개 필요`}
            </Text>
          ) : (
            <Text className={cx('notice')} fontStyle={FontStyle.B3_M} color={Colors.C_GREEN_150}>
              바로 제출 가능
            </Text>
          )}
        </div>
      </div>

      <Link className={cx('column-right')} href={`/acca/result/${data.applySn}`} target="_blank">
        <Icon name="projectLight" width={32} height={32} />
      </Link>
    </button>
  );
};

interface AccResultListProps {
  visible: boolean;
  messageSn?: number;
  initialModal?: string;
  isJD?: boolean;
  onClose: () => void;
  onPrev?: () => void;
  dimmed?: boolean;
  positionSn: number;
}

const AccLightSelectModal: FC<AccResultListProps> = ({
  initialModal,
  messageSn,
  visible,
  onClose,
  isJD = false,
  onPrev,
  positionSn,
  dimmed = true,
}) => {
  const { openModal, isVisible } = useModals(['LIST', 'SELECT', 'SUBMIT', 'APPLY', 'NEW_APPLY'], 'LIST');

  const [selectedResult, setSelected] = useState<AccLightApplyHistoryItemDto | null>(null);

  const [taskRequest, setTaskRequest] = useState<AccLightApplyTaskRq | null>(null);

  const { data: accLightData } = useAccLightApplyHistory(
    {
      positionSn,
      messageRequestYn: !isJD,
    },
    {
      enabled: visible,
    },
  );

  const onClick = (newResult: AccLightApplyHistoryItemDto) => {
    if (selectedResult?.code === newResult.code) {
      setSelected(null);
    } else {
      setSelected(newResult);
    }
  };

  const itemEls = accLightData?.historyList
    ?.sort((a, b) => new Date(b.completedDate).getTime() - new Date(a.completedDate).getTime())
    .map((item) => (
      <AccResultItem
        key={item.code}
        data={item}
        onClick={() => onClick(item)}
        isSelected={item.code === selectedResult?.code}
      />
    ));

  useEffect(() => {
    if (!initialModal) return;

    openModal(initialModal);
  }, [initialModal]);

  return (
    <ResponsiveModal className={cx('modal')} visible={visible} dimmed={dimmed}>
      {isVisible('LIST') && (
        <div className={cx('wrap')}>
          <div className={cx('titleArea')}>
            <Text fontStyle={FontStyle.H4_B}>역량검사 결과를 선택해 주세요.</Text>
            <Text fontStyle={FontStyle.B2_M} color={Colors.C_COOL_GRAY_70}>
              기업 설정에 따라 추가 응시가 필요할 수 있습니다.
            </Text>
          </div>
          <ResponsiveModal.ScrollArea maxHeight={522}>
            <div className={cx('itemWrap')}>
              {isJD && <AccLightNewApply openModal={openModal} />}
              {itemEls}
            </div>
          </ResponsiveModal.ScrollArea>
          <div className={cx('btnArea')}>
            <Button theme="secondary" className={cx('btn')} onClick={isJD ? onPrev : onClose}>
              {isJD ? '이전' : '취소'}
            </Button>
            <Button
              className={cx('btn')}
              onClick={() => {
                openModal('SELECT');
              }}
              disabled={!selectedResult}
            >
              선택 완료
            </Button>
          </div>
        </div>
      )}
      {isVisible('SELECT') && selectedResult && (
        <SelectItem
          isJD={isJD}
          onPrev={() => openModal('LIST')}
          onSubmit={(rq: AccLightApplyTaskRq) => {
            setTaskRequest(rq);
            openModal('SUBMIT');
          }}
          onApply={(rq: AccLightApplyTaskRq) => {
            setTaskRequest(rq);
            openModal('APPLY');
          }}
          positionSn={positionSn}
          selectedResult={selectedResult}
        />
      )}
      {isVisible('SUBMIT') && selectedResult && (
        <Submit
          messageSn={messageSn}
          positionSn={positionSn}
          isJD={isJD}
          onPrev={() => openModal('SELECT')}
          taskRequest={taskRequest}
          onClose={onClose}
        />
      )}
      {isVisible('APPLY') && (
        <AccLightApplyModal
          isJD={isJD}
          onPrev={() => openModal('SELECT')}
          taskRequest={taskRequest}
          positionSn={positionSn}
          onClose={onClose}
        />
      )}
      {isVisible('NEW_APPLY') && (
        <AccLightNewApplyModal
          isNewApply
          isJD={isJD}
          onPrev={() => {
            if (isJD) openModal('LIST');
            else onClose();
          }}
          positionSn={positionSn}
          onClose={onClose}
        />
      )}
    </ResponsiveModal>
  );
};

export default AccLightSelectModal;
