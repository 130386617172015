export interface PositionAccStatusRs {
  accApplyRequiredYn: boolean;
  accLiteStatus: AccLightStatusTypeEnum;
}

export enum AccLightStatusTypeEnum {
  BEFORE_APPLY = 'BEFORE_APPLY',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}
