import { FC } from 'react';
import Icon from '@components/common/assets';
import Text from '@components/common/text';
import Button from '@components/common/button';
import { useAccLightApplyContinue } from '@repository/accLight/useAccLight';
import ResponsiveModal from '@components/_atoms/ResponsiveModal';
import { FontStyle } from '@domain/constant/fontStyle';
import UrlUtil from '@utils/urlUtil';
import useDebounce from '@utils/hooks/useDebounce';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);
interface AccLightContinueModalProps {
  visible: boolean;
  onClose: () => void;
  positionSn: number;
  dimmed?: boolean;
}

const AccLightContinueModal: FC<AccLightContinueModalProps> = ({ visible, positionSn, onClose, dimmed = true }) => {
  const { data: accLightUrl } = useAccLightApplyContinue(positionSn, { enabled: visible });

  const handleContinueBtn = useDebounce(
    () => {
      if (!accLightUrl) return;

      UrlUtil.openWindowWithPost(accLightUrl.url, {
        authenticationCode: accLightUrl.code,
      });
      onClose();
    },
    800,
    true,
  );

  return (
    <ResponsiveModal className={cx('modal')} visible={visible} dimmed={dimmed}>
      <div className={cx('wrap')}>
        <div className={cx('content')}>
          <Icon name="desktopAcca" width={64} height={64} />
          <Text fontStyle={FontStyle.H4_B}>
            제출을 위해 응시 중인 역량검사가 있습니다.
            <br />
            이어서 응시 하시겠습니까?
          </Text>
        </div>
        <div className={cx('btnArea')}>
          <Button theme="secondary" className={cx('btn')} onClick={onClose}>
            취소
          </Button>
          <Button className={cx('btn')} onClick={handleContinueBtn}>
            이어서 응시
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  );
};

export default AccLightContinueModal;
