import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import { AccLightApplyTaskRq } from '@domain/rq/accLight/AccLightApplyTaskRq';
import { AccLightApplyTaskListRq } from '@domain/rq/accLight/AccLightApplyTaskListRq';
import { AccLightApplyHistoryRq } from '@domain/rq/accLight/AccLightApplyHistoryRq';
import { AccLightApplyCompanyRecentRs } from '@domain/rs/accLight/AccLightApplyCompanyRecentRs';
import { AccLightApplyHistoryRs } from '@domain/rs/accLight/AccLightApplyHistoryRs';
import { AccLightApplyTaskListRs } from '@domain/rs/accLight/AccLightApplyTaskListRs';
import { AccLightTaskRs } from '@domain/rs/accLight/AccLightTaskRs';
import { AccLightApplyContinueRs } from '@domain/rs/accLight/AccLightApplyContinueRs';
import { AccLightNewApplyRs } from '@domain/rs/accLight/AccLightNewApplyRs';

export class RemoteAccLightRepo {
  // 불러올 과제 목록 조회
  fetchAccLightTasks(rq: AccLightApplyTaskListRq): Promise<AxiosResponse<AccLightApplyTaskListRs>> {
    return axios.get('/acc/tasks', { params: rq });
  }

  //과제 불러오기
  postAccLightTasks(rq: AccLightApplyTaskRq): Promise<AxiosResponse<AccLightTaskRs>> {
    return axios.post('/acc/tasks', rq);
  }

  //기업별 최근 응시내역 조회
  fetchAccLightApplyRecentCompany(): Promise<AxiosResponse<AccLightApplyCompanyRecentRs>> {
    return axios.get('/acc/center/apply/recent/company');
  }

  //역검 라이트 결과표 리스트 조회
  fetchAccLightApplyHistory(rq: AccLightApplyHistoryRq): Promise<AxiosResponse<AccLightApplyHistoryRs>> {
    return axios.get(`/acc/apply/history`, { params: rq });
  }

  //포지션 역검 이어하기
  fetchAccLightApplyContinue(positionSn: number): Promise<AxiosResponse<AccLightApplyContinueRs>> {
    return axios.get(`/acc/apply/continue`, { params: { positionSn } });
  }

  //포지션 역검 새로응시
  fetchAccLightNewApply(rq: AccLightApplyHistoryRq): Promise<AxiosResponse<AccLightNewApplyRs>> {
    return axios.get(`/acc/apply/first`, { params: rq });
  }
}
